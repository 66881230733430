import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Api from "../../Config/Api";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setAuth } from "../../Reducers/Authentication";
import Logo from "../../assets/images/logo.png";
import LoginImg from "../../assets/images/loginbg.jpg";
import ModalBox from "../Elements/ModalBox";

const CenterLogin = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const {
    register: register1,
    handleSubmit: handleSubmit1,
    formState: { errors: errors1 },
    reset: reset1,
  } = useForm();

  const isAdmin = useSelector((state) => state.authentication.isAdmin);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoginModalShow, setIsLoginModalShow] = useState(false);
  const [centerLoginID, setCenterLoginID] = useState("");

  const loginModalShow = () => {
    setIsLoginModalShow(true);
  };
  const loginModalClose = () => {
    setIsLoginModalShow(false);
    setCenterLoginID("");
  };

  //Login Submit Function
  const onSubmit = async (d) => {
    try {
      setIsLoading(true);
      const body = {
        username: d.username,
        password: d.password,
      };
      const res = await Api("center-login", "POST", body);
      // console.log(res);
      if (res.data.status === 1) {
        setCenterLoginID(res.data.data);
        setIsLoading(false);
        loginModalShow();
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const verifyOtp = async (d) => {
    // console.log(d);
    try {
      setIsLoading(true);
      const body = {
        id: centerLoginID,
        otp: d.otp,
      };
      const res = await Api("verify-login-otp", "POST", body);
      // console.log(res);
      if (res.data.status === 1) {
        loginModalClose();
        setCenterLoginID("");
        reset1();
        dispatch(setAuth(res.data.data));
        navigate("/dashboard");
        setIsLoading(false);
        toast.success("Login Successfully!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  useEffect(() => {
    if (isAdmin) {
      navigate("/dashboard");
    }
  }, [isAdmin, navigate]);

  return (
    <>
      <section className="homeloginsection">
        <div className="col-xl-6 col-lg-8 col-md-10 col-sm-11 col-11">
          <div className="homeloginbox">
            <div className="row g-0 h-100">
              <div className="col-sm-6 d-none d-sm-block">
                <div className="homeloginimg">
                  <img src={LoginImg} alt="login" className="w-100" />
                </div>
              </div>
              <div className="col-sm-6 align-self-center">
                <div className="p-3 p-lg-4">
                  <div className="homelogo">
                    <Link to="/">
                      <img src={Logo} alt="logo" />
                    </Link>
                  </div>
                  <h5 className="text-center mb-4 text-dark">Login</h5>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row g-3">
                      <div className="col-lg-12">
                        <div className="input-box">
                          <input
                            type="text"
                            placeholder="Username"
                            {...register("username", {
                              required: true,
                            })}
                            disabled={isLoading}
                          />
                          {errors.username && (
                            <div className="error_line">Role is required</div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="input-box">
                          <input
                            type="password"
                            placeholder="Enter Password"
                            {...register("password", {
                              required: true,
                            })}
                            disabled={isLoading}
                          />
                          {errors.password && (
                            <div className="error_line">Role is required</div>
                          )}
                        </div>
                      </div>
                      {/* <div className="col-12">
                        <div className="">
                          <Link to="/" className="text-primary fw-medium">
                            Forgot Password?
                          </Link>
                        </div>
                      </div> */}
                      <div className="col-lg-12">
                        <div className="text-center">
                          <button
                            type="submit"
                            disabled={isLoading}
                            className="btn btn-primary w-100"
                          >
                            {isLoading ? "Loading..." : "Login"}
                          </button>
                        </div>
                      </div>
                      <div className="col-lg-12 text-center mt-4 homeloginfooter">
                        <p>
                          Copyright © DRPS. All Rights Reserved{" "}
                          {new Date().getFullYear()}
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ModalBox
        title="Verify OTP"
        show={isLoginModalShow}
        hide={loginModalClose}
      >
        <div>
          <form onSubmit={handleSubmit1(verifyOtp)}>
            <div className="row g-3">
              <div className="col-12">
                <div className="">
                  <h4 className="h5 text-dark mb-2">
                    Please enter the OTP to verify your account
                  </h4>
                  <p className="small">
                    A One Time Password has been sent to your registered mobile
                  </p>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="">
                  <input
                    className="form-control"
                    type="number"
                    placeholder="Enter OTP"
                    {...register1("otp", {
                      required: true,
                    })}
                    disabled={isLoading}
                    autoComplete="new-password"
                  />
                  {errors1.otp && (
                    <div className="error_line">OTP is required</div>
                  )}
                </div>
              </div>
              <div className="col-lg-12">
                <div className="d-flex justify-content-between gap-2">
                  <button
                    type="submit"
                    disabled={isLoading}
                    className="btn btn-primary"
                  >
                    {isLoading ? "Loading..." : "Verify OTP"}
                  </button>
                  {/* <button
                    type="button"
                    disabled={isLoading}
                    className="btn fw-bold text-primary"
                    onClick={onSubmit}
                  >
                    <u>Resend OTP</u>
                  </button> */}
                </div>
              </div>
            </div>
          </form>
        </div>
      </ModalBox>
    </>
  );
};

export default CenterLogin;
