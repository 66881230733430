import React from "react";
import Routing from "./Navigation/Routing";
import { ToastContainer } from "react-toastify";
const App = () => {
  return (
    <>
      <ToastContainer />
      <Routing />
    </>
  );
};

export default App;
