import React, { useEffect, useState } from "react";
// import UserIcon from "../../assets/images/user.png";
import Api from "../../Config/Api";
import { useDispatch, useSelector } from "react-redux";
import { setAuth } from "../../Reducers/Authentication";
import { toast } from "react-toastify";

import { useForm } from "react-hook-form";

const Index = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const admin = useSelector((state) => JSON.parse(state.authentication.admin));
  const adminToken = useSelector((state) => state.authentication.adminToken);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!!admin) {
      // console.log(admin);
      reset({
        center_name: admin.center_name,
        center_email: admin.center_email,
        name: admin.name,
        email: admin.email,
        phone: admin.phone,
        address: admin.address,
        state: admin?.state?.state_name,
        district: admin?.district?.district_name,
        // block: admin?.block?.block,
        username: admin.username,
      });
    }
     // eslint-disable-next-line
  }, [reset]);

  const onSubmit = async (d) => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("center_name", d.center_name);
      formData.append("center_email", d.center_email);
      formData.append("name", d.name);
      formData.append("email", d.email);
      formData.append("phone", d.phone);
      formData.append("address", d.address);

      const res = await Api("center-change-information", "POST", formData);
      // console.log(res);

      if (res.data.status === "success") {
        toast.success("Profile Updated Successfully !", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        const localCenterDataObj = { ...admin };
        const objToken = {
          center_token: JSON.parse(adminToken),
        };
        const newData = {
          ...localCenterDataObj,
          ...res.data.data,
          ...objToken,
        };
        dispatch(setAuth(newData));
        setIsLoading(false);
      }else{
        toast.error("Something went wrong!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="bg-white p-3 rounded p-3 shadow">
        <div className="d-flex justify-content-between mb-3">
          <div className="flex-grow-1">
            <h5 className="text-dark">Change Information</h5>
          </div>
        </div>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row g-3">
              <div className="col-lg-4">
                <label className="form-label">Center Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Center Name"
                  {...register("center_name", { required: true })}
                />
                {errors.center_name && (
                  <div className="error_line">Center Name is required</div>
                )}
              </div>

              <div className="col-lg-4">
                <label className="form-label">Center Email</label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Enter Center Email"
                  {...register("center_email", { required: true })}
                />
                {errors.center_email && (
                  <div className="error_line">Center Email is required</div>
                )}
              </div>
              <div className="col-lg-4">
                <label className="form-label">Authorised Person Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Name"
                  {...register("name", { required: true })}
                />
                {errors.name && (
                  <div className="error_line">
                    Authorised Person Name is required
                  </div>
                )}
              </div>
              <div className="col-lg-4">
                <label className="form-label">Authorised Person Email</label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Enter Email"
                  {...register("email", { required: true })}
                />
                {errors.email && (
                  <div className="error_line">
                    Authorised Person Email is required
                  </div>
                )}
              </div>
              <div className="col-lg-4">
                <label className="form-label">Authorised Person Phone</label>
                <input
                  type="tel"
                  className="form-control"
                  placeholder="Enter Phone"
                  {...register("phone", { required: true })}
                />
                {errors.phone && (
                  <div className="error_line">
                    Authorised Person Phone is required
                  </div>
                )}
              </div>
              <div className="col-lg-4">
                <label className="form-label">Address</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Address"
                  {...register("address", { required: true })}
                />
                {errors.address && (
                  <div className="error_line">Address is required</div>
                )}
              </div>
              <div className="col-lg-4">
                <label className="form-label">State</label>
                <input
                  type="text"
                  className="form-control"
                  {...register("state")}
                  disabled={true}
                />
              </div>
              <div className="col-lg-4">
                <label className="form-label">District</label>
                <input
                  type="text"
                  className="form-control"
                  {...register("district")}
                  disabled={true}
                />
              </div>
              {/* <div className="col-lg-4">
                <label className="form-label">Block</label>
                <input
                  type="text"
                  className="form-control"
                  {...register("block")}
                  disabled={true}
                />
              </div> */}
              <div className="col-lg-4">
                <label className="form-label">Username</label>
                <input
                  type="text"
                  className="form-control"
                  {...register("username")}
                  disabled={true}
                />
              </div>

              <div className="col-lg-3">
                <div>
                  {/* <label className="form-label"></label> */}
                  <input
                    type="submit"
                    value="Change Information"
                    className="btn btn-primary"
                    disabled={isLoading}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Index;
