import React from "react";
import { Modal } from "react-bootstrap";

const ModalBox = ({ show, hide, title, size = "md", children }) => {
  return (
    <Modal
      show={show}
      onHide={hide}
      backdrop="static"
      keyboard={false}
      size={size}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="text-dark">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};

export default ModalBox;
