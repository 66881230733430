import React, { useEffect, useState } from "react";
import Api from "../../Config/Api";
import {
  Chart,
  ArcElement,
  // LineElement,
  BarElement,
  // PointElement,
  // BarController,
  // BubbleController,
  // DoughnutController,
  // LineController,
  // PieController,
  // PolarAreaController,
  // RadarController,
  // ScatterController,
  CategoryScale,
  LinearScale,
  // LogarithmicScale,
  // RadialLinearScale,
  // TimeScale,
  // TimeSeriesScale,
  // Decimation,
  // Filler,
  Legend,
  // Title,
  Tooltip,
} from "chart.js";
import { Bar, Doughnut } from "react-chartjs-2";
// import ChartDataLabels from "chartjs-plugin-datalabels";

import {
  FaMoneyBillWaveAlt,
  FaHourglassHalf,
  FaCheckDouble,
} from "react-icons/fa";
import dayjs from "dayjs";
import ShimmerCard from "../Elements/ShimmerCard";
Chart.register(
  ArcElement,
  // LineElement,
  BarElement,
  // PointElement,
  // BarController,
  // BubbleController,
  // DoughnutController,
  // LineController,
  // PieController,
  // PolarAreaController,
  // RadarController,
  // ScatterController,
  CategoryScale,
  LinearScale,
  // LogarithmicScale,
  // RadialLinearScale,
  // TimeScale,
  // TimeSeriesScale,
  // Decimation,
  // Filler,
  Legend,
  // Title,
  Tooltip
);

const CenterDashboard = () => {
  const [counters, setCounters] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isChartLoading, setIsChartLoading] = useState(false);

  const getCounters = async () => {
    setIsLoading(true);
    const { data } = await Api("center-counter");
    // console.log(data.data[0]);
    if (data.status === 1) {
      setCounters(data.data[0]);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const getCharts = async () => {
    setIsChartLoading(true);
    const { data } = await Api("dashboard");
    // console.log(data);
    if (data.status === 1) {
      setChartData(data.data);
      setIsChartLoading(false);
    } else {
      setIsChartLoading(false);
    }
  };

  let labels = chartData?.map((a) =>
    dayjs(a?.month, "MMMM YYYY").format("MMM YYYY")
  );
  
  let dataCount = chartData?.map((a) => Number(a?.number_of_applications));
  // console.log("labels", labels);
  // console.log("dataCount", dataCount);

  const data1 = {
    labels: labels,
    datasets: [
      {
        data: dataCount,
        backgroundColor: "#31b1ec",
        barThickness: 8,
        borderRadius: 10,
      },
    ],
  };

  const options1 = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        position: "bottom",
      },
    },
  };

  const data2 = {
    labels: ["Approved", "Under Process", "Released"],
    datasets: [
      {
        label: "",
        data: [
          counters?.approved ? counters?.approved : 0,
          counters?.under_process ? counters?.under_process : 0,
          counters?.released ? counters?.released : 0,
        ],
        backgroundColor: ["#31b1ec", "#f7ce68", "#6bca6a"],
        borderWidth: 1,
      },
    ],
  };
  const options2 = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: { display: true, color: "#fff" },
      legend: {
        position: "bottom",
      },
    },
  };

  useEffect(() => {
    getCounters();
    getCharts();
  }, []);

  return (
    <>
      <div className="">
        <div className="mb-3">
          <div className="row g-3 counterboxrow">
            <div className="col-sm-12 col-lg-4">
              {isLoading ? (
                <ShimmerCard height={[40, 20, 20]} />
              ) : (
                <div className="counterbox">
                  <div className="d-flex align-items-center">
                    <div className="counterboxicon">
                      <FaCheckDouble />
                    </div>
                    <div className="counterboxdata">
                      <h3>{counters?.approved ? counters?.approved : 0}</h3>
                    </div>
                  </div>
                  <p>Approved</p>
                  <i className="las la-file-alt counterbgicon"></i>
                </div>
              )}
            </div>
            <div className="col-sm-12 col-lg-4">
              {isLoading ? (
                <ShimmerCard height={[40, 20, 20]} />
              ) : (
                <div className="counterbox">
                  <div className="d-flex align-items-center">
                    <div className="counterboxicon">
                      <FaHourglassHalf />
                    </div>
                    <div className="counterboxdata">
                      <h3>
                        {counters?.under_process ? counters?.under_process : 0}
                      </h3>
                    </div>
                  </div>
                  <p>Under Process</p>
                  <i className="las la-file-alt counterbgicon"></i>
                </div>
              )}
            </div>
            <div className="col-sm-12 col-lg-4">
              {isLoading ? (
                <ShimmerCard height={[40, 20, 20]} />
              ) : (
                <div className="counterbox">
                  <div className="d-flex align-items-center">
                    <div className="counterboxicon">
                      <FaMoneyBillWaveAlt />
                    </div>
                    <div className="counterboxdata">
                      <h3>{counters?.released ? counters?.released : 0}</h3>
                    </div>
                  </div>
                  <p>Released </p>
                  <i className="las la-file-alt counterbgicon"></i>
                </div>
              )}
            </div>
          </div>
        </div>

        <div>
          <div className="row g-3">
            <div className="col-lg-8">
              {isChartLoading ? (
                <ShimmerCard height={[100, 100, 50, 20, 20, 20]} />
              ) : (
                <div className="bg-white p-3 shadow-sm rounded-3">
                  <h5 className="mb-3" style={{ color: "var(--black)" }}>
                    Month Wise Applications
                  </h5>
                  <div className="chartbox">
                    <Bar options={options1} data={data1} />
                  </div>
                </div>
              )}
            </div>
            <div className="col-lg-4">
              {isChartLoading ? (
                <ShimmerCard height={[100, 100, 50, 20, 20, 20]} />
              ) : (
                <div className="bg-white p-3 shadow-sm rounded-3">
                  <h5 className="mb-3" style={{ color: "var(--black)" }}>
                    Total Applications
                  </h5>
                  <div className="chartbox">
                    <Doughnut options={options2} data={data2} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CenterDashboard;
