import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Auth from "./Auth";
import Missing from "../Views/Missing/Missing";

//Center
import Login from "../Views/Login";
import Layout from "../Views/Layout/Index";
import Dashboard from "../Views/Dashboard";
import Applications from "../Views/Application/Applications";
import ApplicationForm from "../Views/Application/ApplicationForm";
import ApplicationAccountDetails from "../Views/Application/ApplicationAccountDetails";
import ApplicationPayment from "../Views/Application/ApplicationPayment";
import ApplicationUploadDocuments from "../Views/Application/ApplicationUploadDocuments";
import ApplicationThankyou from "../Views/Application/ApplicationThankyou";
import ApplicationActivities from "../Views/Application/ApplicationActivities";
import ChangeInformation from "../Views/ChangeInformation/Index";
import ChangePassword from "../Views/ChangePassword/Index";
import PaymentHistory from "../Views/PaymentHistory/Index";

const Routing = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route element={<Auth redirectPath="/login" />}>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Navigate to="dashboard" />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/applications" element={<Applications />} />
          <Route path="/application/form" element={<ApplicationForm />} />
          <Route
            path="/application/application_upload_documents"
            element={<ApplicationUploadDocuments />}
          />
          <Route
            path="/application/account_details"
            element={<ApplicationAccountDetails />}
          />
          <Route path="/application/payment" element={<ApplicationPayment />} />
          <Route
            path="/application/thankyou"
            element={<ApplicationThankyou />}
          />
          <Route
            path="/application/activities"
            element={<ApplicationActivities />}
          />
          <Route
            path="/payment-history"
            element={<PaymentHistory />}
          />
          <Route
            path="/change-information"
            element={<ChangeInformation />}
          />
          <Route path="/change-password" element={<ChangePassword />} />
        </Route>
      </Route>

      <Route path="*" element={<Missing />} />
    </Routes>
  );
};

export default Routing;
