import dayjs from "dayjs";
import React, {useState} from "react";
import ReadMore from "../../Elements/ReadMore";
import StatusBadge from "./StatusBadge";
import { Modal } from "react-bootstrap";

const ApplicationPage = ({ data: maindata }) => {
  const data = maindata?.data;
  const img_url = maindata?.img_url;
  const nature_disaster = maindata?.nature_disaster;
  const damages = maindata?.damages;
  const amount = maindata?.amount;
  // const document_img_url = maindata?.document_img_url;
  const verifying_officer = maindata?.verifying_officer;
  const [showDamageImageModal, setShowDamageImageModal] = useState(false);
  const [selectedfirstDamageId, setSelectedfirstDamageId] = useState("");
  const [selectedDamageImgs, setSelectedDamageImgs] = useState("");

  // useEffect(() => {
  //   console.log(maindata);
  // }, [maindata]);
  const Image = {
    logo: "https://api.nsdmadrps.in/images/application/nsdma_logo.png",
  };

  const openDamageImageModal = (
    firstDamageId,
    DamageImgs
) => {
    console.log('damagelllll', damages);

    setShowDamageImageModal(true);
    setSelectedfirstDamageId(firstDamageId);
    setSelectedDamageImgs(DamageImgs);
  };

  const closeDamageImageModal = () => {
    setShowDamageImageModal(false);
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          padding: "40px",
          borderRadius: "10px",
          backgroundColor: "#fff",
          margin: "0 auto",
          border: "1px solid #ddd",
          fontSize: "14px",
          color: "#000",
        }}
      >
        <div>
          <table style={{ width: "100%", marginBottom: "0" }}>
            <tbody>
              <tr>
                <td>
                  <div className="mt-3">
                    <table className="m-0" style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td style={{ width: "100px" }}>
                            <img
                              src={Image.logo}
                              alt="NSDMA"
                              style={{ height: "100px" }}
                            />
                          </td>
                          <td>
                            <table style={{ width: "100%" }}>
                              <tbody>
                                <tr>
                                  <td
                                    style={{
                                      textAlign: "right",
                                      display: "block",
                                      marginBottom: "10px",
                                      fontSize: "20px",
                                    }}
                                  >
                                    Application No. {data?.application_id}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style={{
                                      textAlign: "right",
                                      lineHeight: "1.4",
                                      fontSize: "25px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    STATE DISASTER RESPONSE FUND (SDRF) FORM
                                    <br />
                                    NAGALAND STATE DISASTER MANAGEMENT AUTHORITY
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div
                    className="mt-3"
                    style={{
                      width: "100%",
                      textAlign: "center",
                      fontStyle: "italic",
                    }}
                  >
                    (To be duly filled in <b>CAPITAL/BLOCK</b> letters with
                    Blue/Black ball pen only. Application are subjected to
                    rejection for non-fulfillment of required data)
                  </div>
                  <div className="mt-3">
                    <table className="m-0" style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td>
                            <div
                              style={{
                                fontStyle: "italic",
                                marginBottom: "10px",
                                fontWeight: "bold",
                              }}
                            >
                              Personal Details:
                            </div>
                            <table
                              style={{ width: "100%" }}
                              className="table table-bordered table-striped table-sm m-0"
                            >
                              <tbody>
                                <tr>
                                  <td>Application Number</td>
                                  <th>{data?.application_id}</th>
                                  <td
                                    rowSpan={32}
                                    style={{
                                      textAlign: "center",
                                      verticalAlign: "top",
                                    }}
                                  >
                                    <div
                                      style={{
                                        border: "1px solid #ddd",
                                        padding: "10px",
                                        width: "150px",
                                        textAlign: "center",
                                        margin: "0 auto",
                                      }}
                                    >
                                      <img
                                        src={data?.applicant_default_img}
                                        className="w-100"
                                        alt="Applicant"
                                      />
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ width: "270px" }}>
                                    Sanction letter Number
                                  </td>
                                  <td>
                                    <b>{data?.sanction_letter_number}</b>
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ width: "270px" }}>
                                    Serial Number
                                  </td>
                                  <td>
                                    <b>{data?.serial_number}</b>
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ width: "270px" }}>First Name</td>
                                  <td>
                                    <b>{data?.applicant_name}</b>
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ width: "270px" }}>Last Name</td>
                                  <td>
                                    <b>{data?.applicant_last_name}</b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Father's Name</td>
                                  <td>
                                    <b>{data?.father_name}</b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Gender</td>
                                  <td>
                                    <b>{data?.gender}</b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Date of Birth</td>
                                  <td>
                                    <b>{data?.dob}</b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Mobile Number</td>
                                  <td>
                                    <b>{data?.mobile}</b>
                                  </td>
                                </tr>

                                <tr>
                                  <td>Alternative Mobile Number</td>
                                  <td>
                                    <b>{data?.alternative_mobile_no}</b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Aadhar No.</td>
                                  <td>
                                    <b>{data?.aadhar_no}</b>
                                  </td>
                                </tr>
                                {/* <tr>
                                  <td>Id Proof Document Type</td>
                                  <td>
                                    <b>
                                      {!!data?.id_proof_document_type
                                        ? data?.id_proof_document_type
                                        : "-"}
                                    </b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Id Proof No</td>
                                  <td>
                                    <b>
                                      {!!data?.id_proof_no
                                        ? data?.id_proof_no
                                        : "-"}
                                    </b>
                                  </td>
                                </tr> */}

                                {/* <tr>
                                  <td>Permanent Address</td>
                                  <td>
                                    <b>{data?.permanent_address}</b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Present Address</td>
                                  <td>
                                    <b>{data?.present_address}</b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Village/Colony/Ward</td>
                                  <td>
                                    <b>{data?.village}</b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>City/Town</td>
                                  <td>
                                    <b>{data?.town}</b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Admin Circle/Sub-division</td>
                                  <td>
                                    <b>{data?.admin_circle}</b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Police Station</td>
                                  <td>
                                    <b>{data?.police_station}</b>
                                  </td>
                                </tr> */}
                                <tr>
                                  <td>Pincode</td>
                                  <td>
                                    <b>{data?.pincode}</b>
                                  </td>
                                </tr>
                                {/* <tr>
                                  <td>State</td>
                                  <td>
                                    <b>{data?.state?.state_name}</b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>District</td>
                                  <td>
                                    <b>{data?.district?.district_name}</b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Center</td>
                                  <td>
                                    <b>{data?.center?.center_name}</b>
                                  </td>
                                </tr> */}
                                <tr>
                                  <td>IFSC Code</td>
                                  <td>
                                    <b>{data?.ifsc}</b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Bank A/C No.</td>
                                  <td>
                                    <b>{data?.account_number}</b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Name of the account holder</td>
                                  <td>
                                    <b>{data?.account_holder_name}</b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Name of the bank</td>
                                  <td>
                                    <b>{data?.bankname}</b>
                                  </td>
                                </tr>

                                <tr>
                                  <td>Branch</td>
                                  <td>
                                    <b>{data?.branch}</b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Is this applicant account?</td>
                                  <td>
                                    <b>{data?.is_bank_account_owner}</b>
                                  </td>
                                </tr>
                                {/* <tr>
                                  <td>Name of the account holder</td>
                                  <td>
                                    <b>{data?.account_holder_name}</b>
                                  </td>
                                </tr> */}
                                <tr>
                                  <td>Relationship with the applicant</td>
                                  <td>
                                    <b>
                                      {!!data?.relationship_with_applicant
                                        ? data?.relationship_with_applicant
                                        : "-"}
                                    </b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>ID/Relation Proof.</td>
                                  <td>
                                    {data?.relation_proof ? (
                                      <a
                                        href={data?.relation_proof}
                                        target="_blank"
                                        className="btn btn-primary btn-sm"
                                        rel="noreferrer"
                                      >
                                        View
                                      </a>
                                    ) : (
                                      "-"
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="mt-3">
                    <div
                      className="mb-2"
                      style={{
                        fontStyle: "italic",
                        fontWeight: "bold",
                      }}
                    >
                      DRPS Center Details:
                    </div>
                    <div className="">
                      <table
                        className="table table-bordered table-striped m-0"
                        style={{ width: "100%" }}
                      >
                        <tbody>
                          <tr>
                            <td colSpan={3}>
                              <div>
                                State : <b>{data?.state?.state_name}</b>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={3}>
                              <div>
                                District : <b>{data?.district?.district_name}</b>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={3}>
                              <div>
                                Center : <b>{data?.center?.center_name}</b>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="mt-3">
                    <div
                      className="mb-2"
                      style={{
                        fontStyle: "italic",
                        fontWeight: "bold",
                      }}
                    >
                      Permanent Address:
                    </div>
                    <div className="">
                      <table
                        className="table table-bordered table-striped m-0"
                        style={{ width: "100%" }}
                      >
                        <tbody>
                          <tr>
                            <td colSpan={3}>
                              <div>
                                Address : <b>{data?.permanent_address}</b>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={3}>
                              <div>
                                Village/Colony/Ward : <b>{data?.village}</b>
                              </div>
                            </td>
                          </tr>
                          {data?.is_applicant_outside_nagaland === "1" ? (
                          <tr>
                            <td colSpan={3}>
                              <div>
                                Applicant Permanent State : <b>{data?.applicant_permanent_state}</b>
                              </div>
                            </td>
                          </tr> ) : ""

                          }
                          <tr>
                            <td colSpan={3}>
                              <div>
                                City/Town : <b>{data?.town}</b>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={3}>
                              <div>
                                Admin Circle/Sub-division :{" "}
                                <b>{data?.admin_circle}</b>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={3}>
                              <div>
                                Police Station : <b>{data?.police_station}</b>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="mt-3">
                    <div
                      className="mb-2"
                      style={{
                        fontStyle: "italic",
                        fontWeight: "bold",
                      }}
                    >
                      Present Address:
                    </div>
                    <div className="">
                      <table
                        className="table table-bordered table-striped m-0"
                        style={{ width: "100%" }}
                      >
                        <tbody>
                          <tr>
                            <td colSpan={3}>
                              <div>
                                Address : <b>{data?.present_address}</b>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={3}>
                              <div>
                                Village/Colony/Ward :{" "}
                                <b>{data?.present_village}</b>
                              </div>
                            </td>
                          </tr>
                          {data?.is_applicant_outside_nagaland === "1" ? (
                          <tr>
                            <td colSpan={3}>
                              <div>
                                Applicant Present State : <b>{data?.applicant_present_state}</b>
                              </div>
                            </td>
                          </tr> ) : ""}
                          <tr>
                            <td colSpan={3}>
                              <div>
                                City/Town : <b>{data?.present_town}</b>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={3}>
                              <div>
                                Admin Circle/Sub-division :{" "}
                                <b>{data?.present_admin_circle}</b>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={3}>
                              <div>
                                Police Station :{" "}
                                <b>{data?.present_police_station}</b>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="mt-3">
                    <div
                      className="mb-2"
                      style={{
                        fontStyle: "italic",
                        fontWeight: "bold",
                      }}
                    >
                      Damage report:
                    </div>
                    <div className="">
                      <table
                        className="table table-bordered table-striped m-0"
                        style={{ width: "100%" }}
                      >
                        <tbody>
                          <tr>
                            <td colSpan={3}>
                              <div>
                                Nature of disaster :{" "}
                                <b>
                                  {nature_disaster
                                    ?.map((a) => a?.title)
                                    .join(", ")}
                                </b>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Location of Disaster :{" "}
                              <b>{data?.location_disaster}</b>
                            </td>

                            <td>
                              Latitude : <b>{data?.latitude}</b>
                            </td>

                            <td>
                              Longitude : <b>{data?.longitude}</b>
                            </td>
                          </tr>

                          <tr>
                            <td colSpan={3}>
                              <p>
                                Deceased :{" "}
                                <b>
                                  {data?.application_extra_field?.deceased ===
                                  "Yes" ? (
                                    <span className="badge bg-success">
                                      {data?.application_extra_field?.deceased}
                                    </span>
                                  ) : data?.application_extra_field
                                      ?.deceased === "No" ? (
                                    <span className="badge bg-danger">
                                      {data?.application_extra_field?.deceased}
                                    </span>
                                  ) : (
                                    data?.application_extra_field?.deceased
                                  )}
                                </b>
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={3}>
                              <p>
                                Whether Any Of The Insurance Has Been Processed
                                Through Nagaland Risk Transfer Initiative? :{" "}
                                <b>
                                  {data?.application_extra_field
                                    ?.risk_transfer_initiative === "Yes" ? (
                                    <span className="badge bg-success">
                                      {
                                        data?.application_extra_field
                                          ?.risk_transfer_initiative
                                      }
                                    </span>
                                  ) : data?.application_extra_field
                                      ?.risk_transfer_initiative === "No" ? (
                                    <span className="badge bg-danger">
                                      {
                                        data?.application_extra_field
                                          ?.risk_transfer_initiative
                                      }
                                    </span>
                                  ) : (
                                    data?.application_extra_field
                                      ?.risk_transfer_initiative
                                  )}
                                </b>
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={3}>
                              <p>
                                Whether The Property Damaged Has Insurance
                                Policy? :{" "}
                                <b>
                                  {data?.application_extra_field
                                    ?.damaged_insurance_policy === "Yes" ? (
                                    <span className="badge bg-success">
                                      {
                                        data?.application_extra_field
                                          ?.damaged_insurance_policy
                                      }
                                    </span>
                                  ) : data?.application_extra_field
                                      ?.damaged_insurance_policy === "No" ? (
                                    <span className="badge bg-danger">
                                      {
                                        data?.application_extra_field
                                          ?.damaged_insurance_policy
                                      }
                                    </span>
                                  ) : (
                                    data?.application_extra_field
                                      ?.damaged_insurance_policy
                                  )}
                                </b>
                              </p>

                              {data?.application_extra_field
                                ?.damaged_insurance_policy === "Yes" ? (
                                <p>
                                  <span>
                                    Insurance Name :{" "}
                                    <b>
                                      {
                                        data?.application_extra_field
                                          ?.damaged_insurance_name
                                      }
                                    </b>
                                  </span>
                                  <span className="mx-4">
                                    Policy Number :{" "}
                                    <b>
                                      {
                                        data?.application_extra_field
                                          ?.damaged_policy_number
                                      }
                                    </b>
                                  </span>
                                </p>
                              ) : (
                                " "
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={3}>
                              <p>
                                Whether The Injured Victim Has Medical Insurance
                                Policy? :{" "}
                                <b>
                                  {data?.application_extra_field
                                    ?.medical_insurance_policy === "Yes" ? (
                                    <span className="badge bg-success">
                                      {
                                        data?.application_extra_field
                                          ?.medical_insurance_policy
                                      }
                                    </span>
                                  ) : data?.application_extra_field
                                      ?.medical_insurance_policy === "No" ? (
                                    <span className="badge bg-danger">
                                      {
                                        data?.application_extra_field
                                          ?.medical_insurance_policy
                                      }
                                    </span>
                                  ) : (
                                    data?.application_extra_field
                                      ?.medical_insurance_policy
                                  )}
                                </b>
                              </p>
                              {data?.application_extra_field
                                ?.medical_insurance_policy === "Yes" ? (
                                <p>
                                  <span>
                                    Insurance Name :{" "}
                                    <b>
                                      {
                                        data?.application_extra_field
                                          ?.medical_insurance_name
                                      }
                                    </b>
                                  </span>
                                  <span className="mx-4">
                                    Policy Number :{" "}
                                    <b>
                                      {
                                        data?.application_extra_field
                                          ?.medical_policy_number
                                      }
                                    </b>
                                  </span>
                                </p>
                              ) : (
                                " "
                              )}
                            </td>
                          </tr>
                          {data?.application_extra_field?.deceased === "Yes" &&
                            !!data?.application_extra_field
                              ?.victim_insurance_policy && (
                              <tr>
                                <td colSpan={3}>
                                  <p>
                                    Whether The Deceased Victim Has Insurance
                                    Policy? :{" "}
                                    <b>
                                      {data?.application_extra_field
                                        ?.victim_insurance_policy === "Yes" ? (
                                        <span className="badge bg-success">
                                          {
                                            data?.application_extra_field
                                              ?.victim_insurance_policy
                                          }
                                        </span>
                                      ) : data?.application_extra_field
                                          ?.victim_insurance_policy === "No" ? (
                                        <span className="badge bg-danger">
                                          {
                                            data?.application_extra_field
                                              ?.victim_insurance_policy
                                          }
                                        </span>
                                      ) : (
                                        data?.application_extra_field
                                          ?.victim_insurance_policy
                                      )}
                                    </b>
                                  </p>
                                  {data?.application_extra_field
                                    ?.victim_insurance_policy === "Yes" ? (
                                    <p>
                                      <span>
                                        Insurance Name :{" "}
                                        <b>
                                          {
                                            data?.application_extra_field
                                              ?.victim_insurance_name
                                          }
                                        </b>
                                      </span>
                                      <span className="mx-4">
                                        Policy Number :{" "}
                                        <b>
                                          {
                                            data?.application_extra_field
                                              ?.victim_policy_number
                                          }
                                        </b>
                                      </span>
                                    </p>
                                  ) : (
                                    " "
                                  )}
                                </td>
                              </tr>
                            )}

                          <tr>
                            <td colSpan={3}>
                              <p>
                                Whether Aplicant Has Received Any Immediate
                                Relief From The Emergency Fund? :{" "}
                                <b>
                                  {data?.application_extra_field
                                    ?.relief_fund === "Yes" ? (
                                    <span className="badge bg-success">
                                      {
                                        data?.application_extra_field
                                          ?.relief_fund
                                      }
                                    </span>
                                  ) : data?.application_extra_field
                                      ?.relief_fund === "No" ? (
                                    <span className="badge bg-danger">
                                      {
                                        data?.application_extra_field
                                          ?.relief_fund
                                      }
                                    </span>
                                  ) : (
                                    data?.application_extra_field?.relief_fund
                                  )}
                                </b>
                              </p>
                              {data?.application_extra_field?.relief_fund ===
                              "Yes" ? (
                                <p>
                                  <span>
                                    Voucher Number :{" "}
                                    <b>
                                      {
                                        data?.application_extra_field
                                          ?.relief_fund_voucher_number
                                      }
                                    </b>
                                  </span>
                                  <span className="mx-4">
                                    Amount :{" "}
                                    <b>
                                      {
                                        data?.application_extra_field
                                          ?.relief_fund_amount
                                      }
                                    </b>
                                  </span>
                                </p>
                              ) : (
                                " "
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={3}>
                              <p>
                                Comment :
                                {!!data?.application_extra_field?.comment
                                  ? data?.application_extra_field?.comment
                                  : "-"}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Date of Occurrence :{" "}
                              {/* <b>{data?.date_of_occurrence}</b> */}
                              <b>{dayjs(data?.date_of_occurrence).format("YYYY-MM-DD")}</b>
                            </td>

                            <td colSpan={2}>
                              {/* <p>
                                <b>Proof of Verification : </b>

                                {verifying_officer[0]?.application_file ? (
                                  <a
                                    href={
                                        verifying_officer[0]?.application_file
                                    }
                                    target="_blank"
                                    className="btn btn-primary btn-sm"
                                    rel="noreferrer"
                                  >
                                    View
                                  </a>
                                ) : (
                                  "-"
                                )}
                              </p> */}
                        <p>
                            <b>Proof of Verification :</b>

                            {verifying_officer && verifying_officer.length > 0 && verifying_officer[0]?.application_file ? (
                                <a
                                href={verifying_officer[0]?.application_file}
                                target="_blank"
                                className="btn btn-primary btn-sm"
                                rel="noreferrer"
                                >
                                View
                                </a>
                            ) : (
                                "-"
                            )}
                        </p>

                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="mt-3">
                      <table className="table table-bordered table-striped m-0">
                        <thead>
                          <tr>
                            <th>Item damaged</th>
                            <th>Extend of damage</th>
                            <th>Owner type</th>
                            <th>Qty</th>
                            <th>Amount per unit</th>
                            <th>Total Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {damages?.map((a, i) => {
                            return (
                              <tr key={i}>
                                <td>
                                  {a?.first_damage_classification?.title} &gt;{" "}
                                  {a?.second_damage_classification?.damage_name}
                                </td>
                                <td>{a?.second_damage_classification?.extent_unit ? `${a?.third_damage_classification?.name} (${a?.second_damage_classification?.extent_unit})` : a?.third_damage_classification?.name}</td>
                                <td>
                                  {a?.owner_type !== "null" && !!a?.owner_type
                                    ? a?.owner_type
                                    : "-"}
                                </td>
                                <td>{a?.qty}</td>
                                <td>{a?.price}</td>
                                <td>{a?.total_amount}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {verifying_officer?.length > 0 && (
                    <div className="mt-3">
                      <div
                        className="mb-2"
                        style={{
                          fontStyle: "italic",
                          fontWeight: "bold",
                        }}
                      >
                        Authentications:
                      </div>
                      <table className="table table-bordered table-striped m-0">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Designation</th>
                          </tr>
                        </thead>
                        <tbody>
                          {verifying_officer?.map((item, i) => {
                            return (
                              <tr key={i}>
                                <td>{i + 1}</td>
                                <td>{item.name}</td>
                                <td>{item.designation}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  )}

                  <div className="mt-3">
                    <div
                      className="mb-2"
                      style={{
                        fontStyle: "italic",
                        fontWeight: "bold",
                      }}
                    >
                      Amount:
                    </div>
                    <table className="mb-0" style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td style={{ width: "50%" }}>
                            <div className="alert alert-success m-0">
                              <p>Final Amount</p>
                              <h4 className="text-success fw-bold fs-4 m-0">
                                {!!amount?.final_amount &&
                                Number(amount?.final_amount) >= 0
                                  ? `Rs. ${amount?.final_amount}/-`
                                  : "0"}
                              </h4>
                            </div>
                          </td>
                          <td style={{ paddingLeft: "20px" }}>
                            <div>
                              <table className="table table-bordered table-striped m-0">
                                <tbody>
                                  <tr>
                                    <th>Extend Of Damage</th>
                                    <td>
                                      {amount?.extend_damage_amount
                                        ? `Rs. ${amount?.extend_damage_amount}/-`
                                        : "-"}
                                    </td>
                                  </tr>
                                  {data?.application_extra_field
                                    ?.relief_fund_amount !== "null" &&
                                    !!data?.application_extra_field
                                      ?.relief_fund_amount && (
                                      <tr>
                                        <th>Emergency fund</th>
                                        <td className="text-danger">
                                          {data?.application_extra_field
                                            ?.relief_fund_amount
                                            ? `Rs. -${data?.application_extra_field?.relief_fund_amount}/-`
                                            : "-"}
                                        </td>
                                      </tr>
                                    )}

                                  <tr>
                                    <th>Final Amount</th>
                                    <th className="text-success">
                                      {!!amount?.final_amount &&
                                      Number(amount?.final_amount) >= 0
                                        ? `Rs. ${amount?.final_amount} /-`
                                        : "0"}
                                    </th>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <Modal
                    show={showDamageImageModal}
                    onHide={closeDamageImageModal}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <Modal.Header closeButton>
                    <Modal.Title className="text-dark">Damage Image</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <div>
                    <h4>Item Damage: {selectedfirstDamageId}</h4>

                    {Array.isArray(selectedDamageImgs) && selectedDamageImgs.length > 0 ? (
                        <table className="table">
                        <thead>
                            <tr>
                            <th scope="col">Index</th>
                            <th scope="col">Image</th>
                            <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {selectedDamageImgs.map((img, index) => (
                            <tr key={index}>
                                <td>{index+1}</td>
                                <td>
                                <img
                                    src={img}
                                    style={{ width: "50px", height: "50px", objectFit: "cover" }}
                                    alt={`Damage ${index + 1}`}
                                />
                                </td>
                                <td>
                                <a
                                    rel="noreferrer"
                                    href={img}
                                    className="badge bg-primary border-0"
                                    target="_blank"
                                    style={{
                                    display: "inline-block",
                                    textAlign: "center",
                                    lineHeight: "25px",
                                    width: "80px",
                                    height: "30px",
                                    }}
                                >
                                    View Image
                                </a>
                                </td>
                            </tr>
                            ))}
                        </tbody>
                        </table>
                    ) : (
                        <p>No applications found.</p>
                    )}
                    </div>
                    </Modal.Body>
                </Modal>

                  {(!!data?.aadhar_image ||
                    !!data?.signature_image ||
                    damages?.length > 0) && (
                    <div className="mt-3">
                      <div
                        className="mb-2"
                        style={{
                          fontStyle: "italic",
                          fontWeight: "bold",
                        }}
                      >
                        Documents:
                      </div>
                      <table className="table table-bordered table-striped m-0">
                        <thead>
                          <tr>
                            <th>Document Name</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* {!!data?.upload_documents?.passport && (
                            <tr>
                              <td>Passport Photo</td>
                              <td>
                                {data?.upload_documents?.passport ? (
                                  <div className="d-flex">
                                    <div className="me-2">
                                      <img
                                        alt="document"
                                        style={{
                                          width: "150px",
                                          height: "80px",
                                          objectFit: "cover",
                                          borderRadius: 10,
                                        }}
                                        src={`${data?.upload_documents?.uploads_document_url}${data?.upload_documents?.passport}`}
                                      />
                                    </div>
                                    <div>
                                      <a
                                        rel="noreferrer"
                                        href={`${data?.upload_documents?.uploads_document_url}${data?.upload_documents?.passport}`}
                                        className="btn btn-primary"
                                        target="_blank"
                                      >
                                        View File
                                      </a>
                                    </div>
                                  </div>
                                ) : (
                                  "-"
                                )}
                              </td>
                            </tr>
                          )} */}
                          {!!data?.aadhar_image && (
                            <tr>
                              <td>Aadhar Document</td>
                              <td>
                                {data?.aadhar_image ? (
                                  <div className="d-flex">
                                    <div className="me-2">
                                      <img
                                        alt="document"
                                        style={{
                                          width: "150px",
                                          height: "80px",
                                          objectFit: "cover",
                                          borderRadius: 10,
                                        }}
                                        src={`${data?.aadhar_image}`}
                                      />
                                    </div>
                                    <div>
                                      <a
                                        rel="noreferrer"
                                        href={`${data?.aadhar_image}`}
                                        className="btn btn-primary"
                                        target="_blank"
                                      >
                                        View File
                                      </a>
                                    </div>
                                  </div>
                                ) : (
                                  "-"
                                )}
                              </td>
                            </tr>
                          )}
                          {/* {!!data?.upload_documents?.certificate && (
                            <tr>
                              <td>Certificate (Medical, Death & Succession)</td>
                              <td>
                                {data?.upload_documents?.certificate ? (
                                  <div className="d-flex">
                                    <div>
                                      <a
                                        rel="noreferrer"
                                        href={`${data?.upload_documents?.uploads_document_url}${data?.upload_documents?.certificate}`}
                                        className="btn btn-primary"
                                        target="_blank"
                                      >
                                        View File
                                      </a>
                                    </div>
                                  </div>
                                ) : (
                                  "-"
                                )}
                              </td>
                            </tr>
                          )} */}
                          {!!data?.signature_image && (
                            <tr>
                              <td>Signature</td>
                              <td>
                                {data?.signature_image ? (
                                  <div className="d-flex">
                                    <div className="me-2">
                                      <img
                                        alt="document"
                                        style={{
                                          width: "150px",
                                          height: "80px",
                                          objectFit: "cover",
                                          borderRadius: 10,
                                        }}
                                        src={`${data?.signature_image}`}
                                      />
                                    </div>
                                    <div>
                                      <a
                                        rel="noreferrer"
                                        href={`${data?.signature_image}`}
                                        className="btn btn-primary"
                                        target="_blank"
                                      >
                                        View File
                                      </a>
                                    </div>
                                  </div>
                                ) : (
                                  "-"
                                )}
                              </td>
                            </tr>
                          )}
                          {/* {data?.upload_documents?.damages?.length > 0 && (
                            <tr>
                              <td>Damage images</td>
                              <td>
                                {data?.upload_documents?.damages ? (
                                  <div className="d-flex gap-3 flex-wrap">
                                    {data?.upload_documents?.damages?.map(
                                      (item, i) => {
                                        return (
                                          <div className="text-center" key={i}>
                                            <div className="me-2">
                                              <img
                                                alt="document"
                                                style={{
                                                  width: "150px",
                                                  height: "80px",
                                                  objectFit: "cover",
                                                  borderRadius: 10,
                                                }}
                                                src={`${data?.upload_documents?.uploads_document_url}${item}`}
                                              />
                                            </div>
                                            <div className="mt-2">
                                              <a
                                                rel="noreferrer"
                                                href={`${data?.upload_documents?.uploads_document_url}${item}`}
                                                className="btn btn-primary"
                                                target="_blank"
                                              >
                                                View Image
                                              </a>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                ) : (
                                  "-"
                                )}
                              </td>
                            </tr>
                          )} */}
                          <tr>
                              <td>Damage images</td>
                              <td>
                                <div className="d-flex gap-3 flex-wrap">
                                {damages?.map((item, i) => (
                                    item?.damage_image ? (
                                    <div className="text-center" key={i}>
                                        <p>{item?.first_damage_classification?.title}</p>
                                        <div className="mt-2">
                                        {/* <a
                                            rel="noreferrer"
                                            href={`${item.damage_image}`}
                                            className="btn btn-primary"
                                            target="_blank"
                                        >
                                            View Image
                                        </a> */}
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={() => openDamageImageModal(
                                                item?.first_damage_classification?.title,
                                                item.damage_image
                                            )}
                                        >
                                            View Image
                                        </button>
                                        </div>
                                    </div>
                                    ) : (
                                    "-"
                                    )
                                ))}
                                </div>
                              </td>
                            </tr>
                        </tbody>
                      </table>
                    </div>
                  )}

                  {data?.application_status_log?.length > 0 && (
                    <div className="mt-3">
                      <div
                        className="mb-2"
                        style={{
                          fontStyle: "italic",
                          fontWeight: "bold",
                        }}
                      >
                        VI. Remarks/Comments:
                      </div>
                      <table className="table table-bordered table-striped m-0">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Designation</th>
                            <th>Remark</th>
                            <th>Status</th>
                            <th>Date</th>
                            <th>Document</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.application_status_log?.map((item, i) => {
                            if (item?.status === "8" || item?.status === "9") {
                              return (
                                <tr key={i}>
                                  <td>{i + 1}</td>
                                  <td>{item.name}</td>
                                  <td>{item.center?.center_name}</td>
                                  <td style={{ width: "30%" }}>
                                    {!!item.remark ? (
                                      <ReadMore>{item.remark}</ReadMore>
                                    ) : (
                                      "-"
                                    )}
                                  </td>
                                  <td>
                                    <StatusBadge status={item.status} />
                                  </td>
                                  <td>
                                    {dayjs(item.created_at).format(
                                      "DD MMM, YYYY - hh:mm a"
                                    )}
                                  </td>
                                  <td>
                                    {item.document ? (
                                      <a
                                        target="_blank"
                                        className="btn btn-primary btn-sm"
                                        href={item.document}
                                        rel="noreferrer"
                                      >
                                        View File
                                      </a>
                                    ) : (
                                      "-"
                                    )}
                                  </td>
                                </tr>
                              );
                            } else {
                              return (
                                <tr key={i}>
                                  <td>{i + 1}</td>
                                  <td>{item.name}</td>
                                  <td>{item.user?.designation}</td>
                                  <td>{item.remark}</td>
                                  <td>
                                    <StatusBadge status={item.status} />
                                  </td>
                                  <td>
                                    {dayjs(item.created_at).format(
                                      "DD MMM, YYYY - hh:mm a"
                                    )}
                                  </td>
                                  <td>
                                    {item.document ? (
                                      <a
                                        target="_blank"
                                        className="btn btn-primary btn-sm"
                                        href={item.document}
                                        rel="noreferrer"
                                      >
                                        View File
                                      </a>
                                    ) : (
                                      "-"
                                    )}
                                  </td>
                                </tr>
                              );
                            }
                          })}
                        </tbody>
                      </table>
                    </div>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ApplicationPage;
