import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Api from "../../Config/Api";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { FaArrowLeft } from "react-icons/fa";

const ApplicationUploadDocuments = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const location = useLocation();
  const { data } = location.state || {};

  // console.log(data.data.id);

  const [isLoading, setIsLoading] = useState(false);

  //uploadDocuments
  const uploadDocuments = async (d) => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("id", String(data.data.id));
      for (let file of d.documents) {
        formData.append("documents[]", file);
      }
      // for(let pair of formData.entries()){
      //   console.log(`${pair[0]} ${pair[1]}`);
      // }

      const res = await Api("uploads-documents", "POST", formData);
      // console.log(res);

      if (res.data.status === "success") {
        navigate("/application/account_details", {
          state: location.state,
          // replace: true,
        });
        setIsLoading(false);
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //uploadDocuments

  return (
    <>
      <div className="bg-white p-3 rounded p-3 shadow">
        <div className="d-flex justify-content-between mb-2">
          <div>
          <button className="btn btn-primary" onClick={()=>navigate(-1)}><FaArrowLeft/> Back</button>
          </div>
          <div className="">
            <h5 className="text-dark">Upload Documents</h5>
          </div>
        </div>
        <div>
          <form
            onSubmit={handleSubmit(uploadDocuments)}
            encType="multipart/form-data"
          >
            <div className="row g-3">
              <div className="col-lg-6">
                <div>
                  <label className="form-label">Select Document(s)</label>
                  <input
                    type="file"
                    className="form-control"
                    multiple
                    {...register("documents", { required: true })}
                    disabled={isLoading}
                  />
                  {errors.documents && (
                    <div className="error_line">Document is required</div>
                  )}
                </div>
              </div>

              <div className="col-lg-6">
                <label className="form-label"></label>

                <button
                  type="submit"
                  className="btn btn-success px-4"
                  disabled={isLoading}
                >
                  {isLoading ? "Uploading..." : "Upload Documents & Continue"}
                </button>
              </div>
              <div className="col-12">
                <div className="alert alert-warning m-0">
                  <p>Upload documents</p>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ApplicationUploadDocuments;
