import React, { useEffect } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
//import Api from "../../Config/Api";

const ApplicationAccountDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data } = location.state || {};

  // useEffect(() => {
  //   console.log(data);
  // }, [data]);

  //transferAmount
  const transferAmount = (e) => {
    e.preventDefault();
    navigate("/application/payment", { state: location.state
      //  replace: true
       });
  };
  //transferAmount

  useEffect(() => {
    // console.log(!data);
    if (!data) {
      navigate("/applications");
      // toast.error("Something went wrong!", {
      //   position: toast.POSITION.BOTTOM_RIGHT,
      // });
    }
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="bg-white p-3 rounded p-3 shadow">
        <div className="d-flex justify-content-between mb-2">
          <div>
          <button className="btn btn-primary" onClick={()=>navigate(-1)}><FaArrowLeft/> Back</button>
          </div>
          <div className="">
            <h5 className="text-dark">Account Details</h5>
          </div>
        </div>
        <div>
          <div>
            <div className="">
              <table className="table table-bordered table-striped">
                <tbody>
                  <tr>
                    <td>Account Number</td>
                    <td className="text-dark">{data?.data.account_number}</td>
                  </tr>
                  <tr>
                    <td>Bank Name</td>
                    <td>{data?.data.bankname}</td>
                  </tr>
                  <tr>
                    <td>Branch</td>
                    <td>{data?.data.branch}</td>
                  </tr>

                  <tr>
                    <td>Account Holder Name</td>
                    <td>{data?.data.account_holder_name}</td>
                  </tr>
                  <tr>
                    <td>IFSC</td>
                    <td>{data?.data.ifsc}</td>
                  </tr>
                  <tr>
                    <td>Amount</td>
                    <td>
                      <b>₹ {data?.amount.final_amount}</b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="mt-3 text-center">
            <button
              type="button"
              onClick={transferAmount}
              className="btn btn-success px-4"
            >
              Transfer Amount
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplicationAccountDetails;
