import React from "react";

const ShimmerCard = ({ height, gap = 5 }) => {
  return (
    <div className="loadbox_card" style={{ gap }}>
      {Array.isArray(height) ? (
        height.map((item, index) => {
          return (
            <span
              key={index}
              className="shine"
              style={{
                height: `${item}px`,
              }}
            ></span>
          );
        })
      ) : (
        <span className="shine" style={{ height: height }}></span>
      )}
    </div>
  );
};

export default ShimmerCard;
