import React from "react";
import Api from "../../Config/Api";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";

const Index = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (d) => {
    try {
      if (d.new_password === d.confirm_password) {
        const body = {
          current_password: d.current_password,
          new_password: d.new_password,
          new_confirm_password: d.new_password,
        };
        const res = await Api("center-change-password", "POST", body);

        if (res.data.status === "error") {
          if (res.data.message.current_password !== "") {
            toast.error("Invalid Current Password !", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          } else if (res.data.message.new_password !== "") {
            toast.error("Invalid New Password !", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        } else if (res.data.status === "success") {
          toast.success("Password Changed Successfully!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          reset();
        }
      } else {
        toast.error("New Password & Confirm Password Not Matching", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        return false;
      }
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  return (
    <>

      <div className="bg-white p-3 rounded p-3 shadow">
        <div className="d-flex justify-content-between mb-3">
          <div className="flex-grow-1">
            <h5 className="text-dark">Change Password</h5>
          </div>
        </div>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row g-3">
              <div className="col-lg-4">
                <label className="form-label">Current Password</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Enter Current Password"
                  {...register("current_password", { required: true })}
                />
                {errors.current_password && (
                  <div className="error_line">Current Password is required</div>
                )}
              </div>

              <div className="col-lg-4">
                <label className="form-label">New Password</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Enter New Password"
                  {...register("new_password", { required: true })}
                />
                {errors.new_password && (
                  <div className="error_line">New Password is required</div>
                )}
              </div>

              <div className="col-lg-4">
                <label className="form-label">Confirm Password</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Enter Confirm Password"
                  {...register("confirm_password", { required: true })}
                />
                {errors.confirm_password && (
                  <div className="error_line">Confirm Password is required</div>
                )}
              </div>

              <div className="col-lg-3">
                <div>
                  <input
                    type="submit"
                    value="Submit"
                    className="btn btn-primary"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Index;
