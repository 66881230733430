import React, { useEffect, useState } from "react";
import UserIcon from "../../../assets/images/user.png";
import { useDispatch, useSelector } from "react-redux";
import { removeAuth } from "../../../Reducers/Authentication";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { RiMenu2Line } from "react-icons/ri";
import { FaCog, FaPowerOff, FaLock, FaMoon, FaSun } from "react-icons/fa";

const Header = ({ toggleBarBtn }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => {
    dispatch(removeAuth());
    navigate("/login");
  };

  const localCenterData = useSelector((state) => state.authentication.center);

  const [loginData, setLoginData] = useState({
    center_name: "",
    center_logo: "",
  });
  const { center_name, center_logo } = loginData;

  const fetchData = () => {
    let obj;
    if (localCenterData) {
      obj = JSON.parse(localCenterData);
    } else {
      obj = "";
    }
    const { center_name, center_logo } = obj;
    setLoginData({
      center_name,
      center_logo,
    });
  };

  useEffect(() => {
    if (localCenterData) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [localCenterData]);

  // Theme Toggle
  const [isDark, setIsDark] = useState(
    localStorage.getItem("theme") === "dark" ? true : false
  );

  useEffect(() => {
    document
      .getElementsByTagName("HTML")[0]
      .setAttribute("data-theme", localStorage.getItem("theme"));
  }, []);

  const toggleThemeChange = () => {
    if (isDark === false) {
      localStorage.setItem("theme", "dark");
      document
        .getElementsByTagName("HTML")[0]
        .setAttribute("data-theme", localStorage.getItem("theme"));
      setIsDark(true);
    } else {
      localStorage.setItem("theme", "light");
      document
        .getElementsByTagName("HTML")[0]
        .setAttribute("data-theme", localStorage.getItem("theme"));
      setIsDark(false);
    }
  };
  // Theme Toggle

  return (
    <>
      <div className="adminheader">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <div className="adminheadermenubtn">
              <button type="button" onClick={toggleBarBtn}>
                <RiMenu2Line />
              </button>
            </div>
            <div className="adminlanguagechangediv">
              {/* <select className="form-select">
                <option>Select Language</option>
                <option>English</option>
              </select> */}
              <div id="google_translate_element"></div>
            </div>
          </div>
          <div className="admintopright">
            <ul>
              <li>
                <div className="themetoggle">
                  <input
                    type="checkbox"
                    id="themecheckbox"
                    onChange={toggleThemeChange}
                    defaultChecked={isDark}
                  />
                  <label htmlFor="themecheckbox">
                    <FaMoon className="moon" />
                    <FaSun className="sun" />
                    <span></span>
                  </label>
                </div>
              </li>

              <li>
                <Dropdown className="admintoprightdropdown">
                  <Dropdown.Toggle
                    variant=""
                    bsPrefix="d-flex align-items-center"
                  >
                    <img
                      src={center_logo ? center_logo : UserIcon}
                      className="user-img"
                      alt="user avatar"
                    />
                    <div className="user-info px-lg-2 d-none d-lg-inline-block">
                      <p className="user-name mb-0">{center_name}</p>
                    </div>
                    <i className="las la-angle-down d-none d-lg-inline-block"></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu align="end">
                    <Dropdown.Item as={Link} to="/change-information">
                      <FaCog className="me-2" />
                      Change Information
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="/change-password">
                      <FaLock className="me-2" />
                      Change Password
                    </Dropdown.Item>
                    <Dropdown.Item as="button" onClick={handleLogout}>
                      <FaPowerOff className="me-2" />
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
