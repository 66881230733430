import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ReadMore = ({ charLimit = 60, children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(
    text.length > charLimit ? true : false
  );
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  const location = useLocation();

  useEffect(() => {
    setIsReadMore(text?.length > charLimit ? true : false);
    // eslint-disable-next-line
  }, [location]);

  return (
    <p>
      {isReadMore ? `${text.slice(0, charLimit)}...` : text}
      {text?.length > charLimit && (
        <span
          onClick={toggleReadMore}
          className="d-inline-block ms-1 text-primary fw-bold cursor-pointer"
        >
          {isReadMore ? "read more" : "read less"}
        </span>
      )}
    </p>
  );
};

export default ReadMore;
