import React, { useEffect, useState, useRef, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Api from "../../Config/Api";
import Webcam from "react-webcam";
import ApplicationPage from "./Components/ApplicationPage";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import GifImg from "../../assets/images/loader.gif";
import DefaultImg from "../../assets/images/default_img.jpg";
import { FiCheckCircle } from "react-icons/fi";
import "@fancyapps/ui/dist/fancybox.css";
import { Fancybox } from "@fancyapps/ui";
import { FaArrowLeft, FaPrint } from "react-icons/fa";
// import { FiChevronRight } from "react-icons/fi";
import ReactToPrint from "react-to-print";

const ApplicationForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state || {};
  let componentRef = useRef();
  Fancybox.bind("[data-fancybox]", {
    // Your options go here
  });

  const [applicationDetail, setApplicationDetail] = useState({
    data: "",
    img_url: "",
    nature_disaster: [],
    damages: [],
    amount: "",
    document_img_url: "",
  });
  const [applicationDocuments, setApplicationDocuments] = useState([]);
  const [cameraModalBox, setCameraModalBox] = useState(false);
  const [isCameraBoxOpen, setIsCameraBoxOpen] = useState(false);

  const [isFetching, setIsFetching] = useState(false);
  const [imgSrc, setImgSrc] = useState(null);
  const [isSavingPhoto, setIsSavingPhoto] = useState(false);
  const [isUploadingPhoto, setIsUploadingPhoto] = useState(false);
  // const [documentUrl, setDocumentUrl] = useState("");
  // const [documentModalBox, setDocumentModalBox] = useState(false);
  const webcamRef = useRef(null);

  // const documentModalClose = () => {
  //   setDocumentModalBox(false);
  // };
  // const openModal = (data) => (e) => {
  //   setDocumentModalBox(true);
  //   setDocumentUrl(data);
  // };

  //getApplication
  const getApplication = async () => {
    try {
      setIsFetching(true);
      const body = {
        id: data.id,
      };
      const res = await Api("view_applicant_details", "POST", body);

    //   console.log(res.data);
      if (res.data.status === 1) {
        setApplicationDetail({
          data: res.data.ApplicantDetails.Details,
          img_url: res.data.ApplicantDetails.img_url,
          nature_disaster: res.data.ApplicantDetails.nature_disaster,
          damages: res.data.ApplicantDetails.damages,
          amount: res.data.ApplicantDetails.amount,
          document_img_url: res.data.ApplicantDetails.document_img_url,
          verifying_officer: res.data.ApplicantDetails.verifying_officer,
        });
        // setApplicationDetail(res.data.data);
        setApplicationDocuments(
          res?.data?.ApplicantDetails?.Details?.documents
        );
        setIsFetching(false);
      } else {
        toast.error("Something went wrong!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsFetching(false);
      }
    } catch (error) {
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //getApplication

  // useEffect(() => {
  //   console.log(applicationDocuments);
  // }, [applicationDocuments])

  //Camera Modal
  const openCamera = (e) => {
    e.preventDefault();
    setCameraModalBox(true);
    setIsCameraBoxOpen(true);
  };

  const cameraModalClose = () => {
    setCameraModalBox(false);
    setIsCameraBoxOpen(false);
    setImgSrc(null);
  };
  //Camera Modal

  //Camera Properties
  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user",
  };
  //Camera Properties

  //Capture Image
  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
    setIsCameraBoxOpen(false);
  }, [webcamRef, setImgSrc]);
  //Capture Image

  //Capture Image
  const captureAgain = () => {
    setImgSrc(null);
    setIsCameraBoxOpen(true);
  };
  //Capture Image

  //uploadImage

  const uploadApplicantImage = async (e) => {
    try {
      e.preventDefault();
      setIsSavingPhoto(true);
      const uploadimgdata = imgSrc.replace("data:image/jpeg;base64,", "");
      const res = await Api("send-applicant-img", "POST", {
        id: data.id,
        applicant_img: uploadimgdata,
      });
      // console.log(applicationDetail);
      if (res.data.status === "success") {
        setCameraModalBox(false);
        navigate("/application/account_details", {
          state: {
            data: applicationDetail,
          },
          // replace: true,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //uploadImage

  //uploadDocuments
  const uploadDocuments = async (e) => {
    try {
      setIsUploadingPhoto(true);
      const uploadimgdata = imgSrc.replace("data:image/jpeg;base64,", "");
      const body = {
        id: data.id,
        applicant_img: uploadimgdata,
      };
      const res = await Api("send-applicant-img", "POST", body);
      if (res.data.status === "success") {
        setCameraModalBox(false);
        navigate("/application/application_upload_documents", {
          state: {
            data: applicationDetail,
          },
          // replace: true,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //uploadDocuments

  useEffect(() => {
    if (!!data.id) {
      getApplication();
    } else {
      navigate("/applications");
      // toast.error("Something went wrong!", {
      //   position: toast.POSITION.BOTTOM_RIGHT,
      // });
    }
    // eslint-disable-next-line
  }, []);

  const setDefaultImg = (e, Img) => {
    e.target.src = Img;
  };

  return (
    <>
      <div className={`loaderoverlay ${isFetching && "show"}`}>
        <div>
          <img src={GifImg} alt="Loader" />
          <p>Loading...</p>
        </div>
      </div>
      <div className="bg-white p-3 rounded p-3 shadow">
        <div>

        <div className="d-flex justify-content-between">
          <div>
              <button className="btn btn-primary" onClick={()=>navigate(-1)}><FaArrowLeft/> Back</button>
          </div>
          <div className="mb-2">
            <ReactToPrint
              trigger={() => (
                <button type="button" className="btn btn-success px-5">
                  <FaPrint /> Print
                </button>
              )}
              content={() => componentRef}
            />
          </div>
        </div>


          <div className="table-responsive">
            <div ref={(el) => (componentRef = el)}>
              <ApplicationPage data={applicationDetail} />
            </div>
          </div>
          <div className="mt-4 text-center d-flex align-items-center justify-content-center gap-2">
          <div>
            <button className="btn btn-primary" onClick={()=>navigate(-1)}><FaArrowLeft/> Back</button>
          </div>
            <div>
              {(applicationDetail.data.status === "6" ||
                applicationDetail.data.status === "8") && (
                <button
                  type="button"
                  onClick={openCamera}
                  className="btn btn-primary px-5"
                >
                  Next
                </button>
              )}
            </div>
            {(applicationDetail.data.status === "9" ||
             applicationDetail.data.status === "10")
             && (
              <div className="row g-4 justify-content-center">
                {applicationDocuments?.length > 0 && (
                  <div className="col-lg-12">
                    <div className="alert alert-warning m-0">
                      <h3 className="text-uppercase fs-5  mb-3">Documents</h3>
                      <div className="text-center d-flex gap-3 justify-content-center">
                        {applicationDocuments?.map((data, i) => (
                          // <button
                          //   onClick={openModal(data)}
                          //   key={i}
                          //   className="btn btn-primary"
                          // >
                          //   Document {i + 1}
                          // </button>
                          <a
                            // href={`${applicationDetail?.document_img_url}${data}`}
                            href={data}
                            key={i}
                            className="btn btn-primary"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Document {i + 1}
                          </a>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-lg-12">
                  <div className="alert alert-success thankyou_box rounded-4 p-3 m-0">
                    <FiCheckCircle className="fs-1 text-success mb-3" />
                    <h5 className="m-0">
                    The payment is completed
                    </h5>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal
        size="xl"
        show={cameraModalBox}
        onHide={cameraModalClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-dark">Capture Photo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="row g-2 justify-content-center">
              <div className="col-md-6">
                <div>
                  <div className="camera_box">
                    <a
                      href={
                        applicationDetail?.data?.applicant_default_img
                          ? applicationDetail?.data?.applicant_default_img
                          : DefaultImg
                      }
                      data-fancybox
                    >
                      <img
                        src={
                          applicationDetail?.data?.applicant_default_img
                            ? applicationDetail?.data?.applicant_default_img
                            : DefaultImg
                        }
                        alt="applicant_default_img"
                        className="w-100"
                        onError={(e) => setDefaultImg(e, DefaultImg)}
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row g-2 justify-content-center">
                  <div className="col-sm-12">
                    {isCameraBoxOpen && (
                      <div className="camera_box">
                        <Webcam
                          audio={false}
                          ref={webcamRef}
                          screenshotFormat="image/jpeg"
                          videoConstraints={videoConstraints}
                          forceScreenshotSourceSize="true"
                          mirrored={true}
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                    )}
                    {imgSrc && (
                      <div className="camera_box">
                        <img src={imgSrc} alt="My" className="w-100" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="alert alert-warning m-0">
                  <p>In case face not matching please upload the documents</p>
                </div>
              </div>
            </div>
            <div className="text-center mt-3">
              {!imgSrc ? (
                <button onClick={capture} className="btn btn-primary">
                  Capture photo
                </button>
              ) : (
                <button onClick={captureAgain} className="btn btn-primary">
                  Capture Again
                </button>
              )}

              {imgSrc && (
                <>
                  <button
                    type="button"
                    onClick={uploadDocuments}
                    className="btn btn-warning ms-3"
                    disabled={isUploadingPhoto}
                  >
                    {!isUploadingPhoto ? "Upload Documents" : "Saving Photo..."}
                  </button>
                  <button
                    type="button"
                    onClick={uploadApplicantImage}
                    className="btn btn-success ms-3"
                    disabled={isSavingPhoto ? true : false}
                  >
                    {!isSavingPhoto
                      ? "Save photo & Continue"
                      : "Saving Photo..."}
                  </button>
                </>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* <Modal
        size="xl"
        show={documentModalBox}
        onHide={documentModalClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-dark">Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="document_iframe">
            <iframe
              title="Document"
              src={`${applicationDetail?.document_img_url}${documentUrl}`}
            ></iframe>
          </div>
        </Modal.Body>
      </Modal> */}
    </>
  );
};

export default ApplicationForm;
