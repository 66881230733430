import React, { useEffect, useState } from "react";
import Api from "../../Config/Api";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import OTPInput from "otp-input-react";
import Loader from "../Elements/Loader";
import StatusBadge from "./Components/StatusBadge";
import Table from "../Elements/Table";
import TooltipWidget from "../Elements/TooltipWidget";
import { FaTelegramPlane, FaEye, FaListUl, FaFilter } from "react-icons/fa";
import Select from "react-select";
import DatePicker from "react-multi-date-picker";
import dayjs from "dayjs";
import Collapse from "react-bootstrap/Collapse";

const Applications = () => {
  const {
    control,
    register,
    handleSubmit,
    watch,
    // reset,
    setValue,
    formState: { errors: error },
  } = useForm();
  const navigate = useNavigate();

  // const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [application, setApplication] = useState([]);
  const [isApplicationLoaded, setIsApplicationLoaded] = useState(false);
  const [filter, setFilter] = useState("");
  // const [isOtpSend, setIsOtpSend] = useState(false);
  const [otpModalBox, setOtpModalBox] = useState(false);
  const [applicationState, setApplicationState] = useState([]);
  const [disasterList, setDisasterList] = useState([]);
  const [damageLevel1List, setDamageLevel1List] = useState([]);
  const [damageLevel2List, setDamageLevel2List] = useState([]);
  const [damageLevel3List, setDamageLevel3List] = useState([]);
  const [isThirdDropdownShow, setIsThirdDropdownShow] = useState(false);

  const style = {
    control: (base, state) => ({
      ...base,
      border: state.isFocused ? "1px solid #224b8b" : "1px solid #ddd",
      boxShadow: "none",
      backgroundColor: state.isFocused ? "#fff" : "#fff",
      "&:hover": {
        borderColor: "#224b8b",
      },
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? "#224b8b" : "",
      "&:hover": {
        color: "#fff",
        backgroundColor: "#224b8b",
      },
    }),
  };

  const level1Option = (data) => {
    return data
      ?.filter((d) => d.status === "Active")
      .map((item) => {
        return {
          label: item.title,
          value: item.id,
          ...item,
        };
      });
  };

  const level2Option = (data) => {
    return data
      ?.filter((d) => d.status === "Active")
      .map((item) => {
        return {
          label: item.damage_name,
          value: item.id,
          ...item,
        };
      });
  };

  const level3Option = (data) => {
    // console.log(data);
    return data
      ?.filter((d) => d.status === "Active")
      .map((item) => {
        return {
          label: !!item?.second_damage?.extent_unit
            ? `${item.name} (${item?.second_damage?.extent_unit})`
            : item.name,
          value: item.id,
          ...item,
        };
      });
  };

  const getlevel1 = async () => {
    try {
      setIsLoading(true);
      const res = await Api("first_damage_classification_get");
      // console.log(res);
      if (res.data.status === "1") {
        setDamageLevel1List(
          level1Option(res.data.DamageClassificationData.reverse())
        );
        setIsLoading(false);
      } else {
        toast.error(res?.data?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  //damage level 2
  const getlevel2 = async (id) => {
    try {
      // setIsFetching(true);
      setIsLoading(true);
      //   console.log(id);

      const res = await Api(`second_damage_classification_get/${id}`);
      //   console.log(res);
      if (res.data.status === 1) {
        setDamageLevel2List(level2Option(res.data.DamageClassificationData));
        setIsLoading(false);
        // setIsFetching(false);
        return true;
      } else {
        toast.error(res?.data?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        // setIsFetching(false);
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //damage level 2

  //damage level 3
  const getlevel3 = async (id) => {
    setIsThirdDropdownShow(false);
    setValue("third_damage_classification_id", "");
    try {
      // setIsFetching(true);
      setIsLoading(true);
      const res = await Api(`third_damage_classification_get/${id}`);

      if (res.data.status === 1) {
        setDamageLevel3List(level3Option(res.data.data));
        if (res.data.data.length > 1) {
          setIsThirdDropdownShow(true);
        } else {
          // console.log(res.data.data[0]);

          let tempData = {
            label: res.data.data[0].name,
            value: res.data.data[0].id,
            ...res.data.data[0],
          };

          setValue("third_damage_classification_id", tempData);
        }
        setIsLoading(false);
        // setIsFetching(false);
        return true;
      } else {
        toast.error(res?.data?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        // setIsFetching(false);
        setIsLoading(false);
      }
    } catch (error) {
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //damage level 3

  //getDisaster
  const getDisaster = async () => {
    try {
      setIsLoading(true);
      const res = await Api("nature_of_disaster_get");
      // console.log(res);
      if (res.data.status === 1) {
        setDisasterList(level1Option(res.data.NatureOfDisaster));
        setIsLoading(false);
      } else {
        toast.error(res?.data?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //getDisaster

  const otpModalShow = () => {
    setOtpModalBox(true);
  };

  const otpModalClose = () => {
    setOtpModalBox(false);
    setApplicationState({
      status: "",
      center_id: "",
      id: "",
    });
  };

  //Application Get

  const getApplication = async (props) => {
    try {
      //   setIsLoading(true);
      setIsApplicationLoaded(false);
      const body = !!props
        ? Object.fromEntries(
            Object.entries(props).filter(([key, value]) => value)
          )
        : {};

      //   console.log("body", body);
      const res = await Api("application_list", "POST", body, true);
      // console.log(res);
      if (res.data.status === 1) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setApplication(res.data.data);
        setIsApplicationLoaded(true);
        setIsLoading(false);
        return true;
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
        setIsApplicationLoaded(true);
        return false;
      }
    } catch (error) {
      // toast.error("Something went wrong!", {
      //   position: toast.POSITION.BOTTOM_RIGHT,
      // });
    }
  };

  //Application Get

  //Send OTP
  const sendOTP = async (data) => {
    try {
      setIsLoading(true);
      const body = {
        id: data.id,
      };
      const res = await Api("send-otp", "POST", body);

      if (res.data.status === "succes") {
        toast.success("OTP Sent Successfully !", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //Send OTP

  //Verify OTP
  const openVerifyModal = (d) => {
    otpModalShow();
    setApplicationState(d);
  };

  const verifyOTP = async (d) => {
    try {
      setIsLoading(true);
      if (d.otp === "") {
        toast.error("Enter OTP", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        return false;
      } else {
        // setIsOtpSend(true);
        const body = {
          id: applicationState.id,
          otp: d.otp,
        };
        const res = await Api(`verify-otp`, "POST", body);
        // console.log(res);
        if (res.data.status === "success") {
          otpModalClose();
          setIsLoading(false);
          navigate("/application/form", { state: applicationState });
        } else {
          toast.error("Invalid OTP", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setIsLoading(false);
        }
      }
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  //OTP

  //filter
  const searchInput = (e) => {
    setFilter(e.target.value.trim());
  };

  // const filtered = !filter
  //   ? application
  //   : application.filter((dataList) => {
  //       return (
  //         dataList?.application_id
  //           ?.toLowerCase()
  //           .includes(filter.toLowerCase()) ||
  //         dataList?.applicant_name
  //           ?.toLowerCase()
  //           .includes(filter.toLowerCase()) ||
  //         dataList?.aadhar_no?.toLowerCase().includes(filter.toLowerCase()) ||
  //         dataList?.mobile?.toLowerCase().includes(filter.toLowerCase()) ||
  //         dataList?.gender?.toLowerCase().includes(filter.toLowerCase()) ||
  //         dataList?.nature
  //           ?.map((a) => a?.title.toLowerCase())
  //           .join(", ")
  //           .includes(filter.toLowerCase()) ||
  //         dataList?.application_third_meta
  //           ?.map((a) => a?.first_damage_classification?.title.toLowerCase())
  //           .join(", ")
  //           .includes(filter.toLowerCase()) ||
  //         dataList?.application_third_meta
  //           ?.map((a) =>
  //             a?.second_damage_classification?.damage_name.toLowerCase()
  //           )
  //           .join(", ")
  //           .includes(filter.toLowerCase()) ||
  //         dataList?.application_third_meta
  //           ?.map((a) => a?.third_damage_classification?.name.toLowerCase())
  //           .join(", ")
  //           .includes(filter.toLowerCase()) ||
  //         dataList?.location_disaster
  //           .toLowerCase()
  //           .includes(filter.toLowerCase()) ||
  //         dataList?.application_extra_field?.deceased
  //           .toLowerCase()
  //           .includes(filter.toLowerCase())
  //       );
  //     });

  const filterDataChangeFormat = (listdata) => {
    let b = { ...listdata };
    delete b["applicant_default_img"];
    delete b["applicant_img"];
    delete b["district_id"];
    delete b["id"];
    delete b["nature_disaster"];
    delete b["status"];
    b.nature = b?.nature?.map((x) => x.title).join(", ");
    b.district = b?.district?.district_name;
    b.first_damage_classification = b?.application_third_meta
      ?.map((x) => x?.first_damage_classification?.title)
      .join(", ");
    b.second_damage_classification = b?.application_third_meta
      ?.map((x) => x?.second_damage_classification?.damage_name)
      .join(", ");
    b.third_damage_classification = b?.application_third_meta
      ?.map((x) => x?.third_damage_classification?.name)
      .join(", ");
    delete b["application_third_meta"];
    delete b["application_extra_field"]["application_id"];
    let insidedata = b?.application_extra_field;
    delete b["application_extra_field"];
    let newarr = { ...b, ...insidedata };
    return newarr;
  };

  const filtered = !filter
    ? application
    : application.filter((dataList) => {
        return Object.values(filterDataChangeFormat(dataList))
          .filter((a) => a !== null)
          .some((value) =>
            String(value).toLowerCase().includes(filter.toLowerCase())
          );
      });

  // viewActivities
  const viewActivities = (data) => {
    navigate("/application/activities", { state: data });
  };
  // viewActivities

  // const filterStatus = (e) => {
  //   if (!!e.target.value) {
  //     setIsApplicationLoaded(false);
  //     getApplication(e.target.value);
  //   } else {
  //     setIsApplicationLoaded(false);
  //     getApplication();
  //   }
  // };

  useEffect(() => {
    getApplication();
    getlevel1();
    getDisaster();
    // eslint-disable-next-line
  }, []);

  const onSubmit = async (d) => {
    getApplication({
      gender: d?.gender,
      status: d?.status,
      deceased: d?.deceased,
      nature_disaster: d?.nature_disaster,
      first_damage_classification_id: d?.first_damage_classification_id?.id,
      second_damage_classification_id: d?.second_damage_classification_id?.id,
      third_damage_classification_id: d?.third_damage_classification_id?.id,
      sanction_letter_number: d?.sanction_letter_number,
      location_disaster: d?.location_disaster,
      pincode: d?.pincode,
      village: d?.village,
      town: d?.town,
      admin_circle: d?.admin_circle,
      police_station: d?.police_station,
      start_date: d?.start_date,
      end_date: d?.end_date,
    });
  };

  const columns = [
    {
      name: "#",
      selector: (props) => filtered.indexOf(props) + 1,
      sortable: true,
      width: "60px",
    },
    {
      name: "Application ID",
      selector: (row) => row.application_id,
      sortable: true,
      wrap: true,
    },
    {
      //   name: "Applicant Image",
      name: "Applicant Passport Photo",
      selector: (row) => (
        <img
          src={row.applicant_default_img}
          alt="Applicant"
          style={{
            height: "70px",
            width: "100px",
            objectFit: "cover",
          }}
        />
      ),
    },
    {
      name: "Applicant Info",
      selector: (row) => (
        <>
          <div className="mb-1">{row.applicant_name}</div>
          <div>{row.mobile}</div>
        </>
      ),
    },
    {
      name: "Nature Of Disaster",
      selector: (row) => row.nature?.map((a) => a.title).join(", "),
      sortable: true,
      wrap: true,
    },
    {
      name: "Item Damaged",
      selector: (row) =>
        row.application_third_meta
          ?.map((a) => a.first_damage_classification.title)
          .join(", "),
      sortable: true,
      wrap: true,
    },
    {
      name: "Category",
      selector: (row) =>
        row.application_third_meta
          ?.map((a) => a.second_damage_classification.damage_name)
          .join(", "),
      sortable: true,
      wrap: true,
    },

    {
      name: "District",
      selector: (row) => row.district?.district_name,
      sortable: true,
      wrap: true,
    },
    {
      name: "Status",
      selector: (row) => <StatusBadge status={row.status} />,
      sortable: false,
      wrap: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="d-flex gap-1 py-1">
          <TooltipWidget title="Send OTP">
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={() => sendOTP(row)}
              disabled={isLoading}
            >
              <FaTelegramPlane />
            </button>
          </TooltipWidget>
          <TooltipWidget title="View Application">
            <button
              className="btn btn-success btn-sm"
              onClick={() => openVerifyModal(row)}
            >
              <FaEye />
            </button>
          </TooltipWidget>
          <TooltipWidget title="View Activities">
            <button
              className="btn btn-warning btn-sm"
              onClick={() => viewActivities(row)}
            >
              <FaListUl />
            </button>
          </TooltipWidget>
        </div>
      ),
      minWidth: "180px",
    },
  ];

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  return (
    <>
      <div className="bg-white p-3 rounded p-3 shadow">
        <div className="d-flex gap-2 mb-3">
          <div className="flex-grow-1">
            <h5 className="text-dark">Applications</h5>
          </div>
          <div className="flex-shrink-0">
            <button
              onClick={() => setIsFilterOpen(!isFilterOpen)}
              type="button"
              className="btn btn-primary"
            >
              <FaFilter className="me-2" />
              Filter
            </button>
          </div>
        </div>
        <Collapse in={isFilterOpen}>
          <div className="mb-3">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row g-2">
                <div className="col-xl-3 col-lg-4 col-sm-6">
                  <div className="">
                    <select
                      className="form-select"
                      {...register("gender")}
                      disabled={isLoading}
                    >
                      <option value="">Select Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Others">Others</option>
                    </select>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-sm-6">
                  <div className="">
                    <select
                      className="form-select"
                      disabled={isLoading}
                      {...register("status")}
                    >
                      <option value="">Select Status</option>
                      <option value="6">Final Approved</option>
                      <option value="8">Under Process</option>
                      <option value="9">Released</option>
                    </select>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-4 col-sm-6">
                  <div className="">
                    <select
                      className="form-select"
                      {...register("deceased")}
                      disabled={isLoading}
                    >
                      <option value="">Select Deceased</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-4 col-sm-6">
                  <div className="">
                    <select
                      className="form-select"
                      disabled={isLoading}
                      {...register("nature_disaster")}
                    >
                      <option value="">Select Nature Of Disaster</option>
                      {disasterList
                        ?.filter((NatureOfDisaster) => {
                          return NatureOfDisaster.status === "Active";
                        })
                        .map((NatureOfDisaster) => {
                          return (
                            <option
                              value={NatureOfDisaster?.id}
                              key={NatureOfDisaster?.id}
                            >
                              {NatureOfDisaster?.title}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                  <Controller
                    control={control}
                    name="first_damage_classification_id"
                    onFocus={true}
                    render={({ field }) => (
                      <Select
                        {...field}
                        autoFocus={field.ref}
                        isDisabled={isLoading}
                        styles={style}
                        placeholder={"Select Item Damaged"}
                        options={damageLevel1List}
                        onChange={(e) => {
                          field.onChange(e);
                          getlevel2(e.value);
                          setValue("second_damage_classification_id", "");
                          setValue("third_damage_classification_id", "");
                          setIsThirdDropdownShow(false);
                          setDamageLevel3List([]);
                        }}
                      />
                    )}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                  <Controller
                    control={control}
                    name="second_damage_classification_id"
                    render={({ field }) => (
                      <Select
                        {...field}
                        isDisabled={isLoading}
                        styles={style}
                        placeholder={"Select Category"}
                        options={damageLevel2List}
                        onChange={(e) => {
                          field.onChange(e);
                          getlevel3(e.value);
                        }}
                      />
                    )}
                  />
                </div>
                <div
                  className={`col-xl-3 col-lg-4 col-md-6 col-sm-6 ${
                    isThirdDropdownShow ? "d-block" : "d-none"
                  }`}
                >
                  <Controller
                    control={control}
                    name="third_damage_classification_id"
                    render={({ field }) => (
                      <Select
                        {...field}
                        isDisabled={isLoading}
                        styles={style}
                        placeholder={"Select Sub Category"}
                        options={damageLevel3List}
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                      />
                    )}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-sm-6">
                  <div className="">
                    <input
                      type="text"
                      placeholder="Sanction Letter Number"
                      className="form-control"
                      disabled={isLoading}
                      {...register("sanction_letter_number")}
                    />
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-sm-6">
                  <div className="">
                    <input
                      type="text"
                      placeholder="Location of disaster"
                      className="form-control"
                      disabled={isLoading}
                      {...register("location_disaster")}
                    />
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-sm-6">
                  <div className="">
                    <input
                      type="text"
                      placeholder="Pincode"
                      className="form-control"
                      disabled={isLoading}
                      {...register("pincode")}
                    />
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-sm-6">
                  <div className="">
                    <input
                      type="text"
                      placeholder="Village/Colony/Ward"
                      className="form-control"
                      disabled={isLoading}
                      {...register("village")}
                    />
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-sm-6">
                  <div className="">
                    <input
                      type="text"
                      placeholder="City/Town"
                      className="form-control"
                      disabled={isLoading}
                      {...register("town")}
                    />
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6">
                  <div className="">
                    <input
                      type="text"
                      placeholder="Admin Circle/Sub-division"
                      className="form-control"
                      disabled={isLoading}
                      {...register("admin_circle")}
                    />
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-sm-6">
                  <div className="">
                    <input
                      type="text"
                      placeholder="Police Station"
                      className="form-control"
                      disabled={isLoading}
                      {...register("police_station")}
                    />
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-sm-6">
                  <div className="">
                    <Controller
                      control={control}
                      name="start_date"
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          inputClass="form-control"
                          placeholder="From Date"
                          maxDate={new Date()}
                          format="YYYY-MM-DD"
                          editable={true}
                          value={field.value || ""}
                          onChange={(date) => {
                            field.onChange(date?.format?.("YYYY-MM-DD"));
                            // startDateChange(date?.format?.("YYYY-MM-DD"));
                          }}
                          disabled={isLoading}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-sm-6">
                  <div className="">
                    <Controller
                      control={control}
                      name="end_date"
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          inputClass="form-control"
                          placeholder="To Date"
                          maxDate={new Date()}
                          minDate={dayjs(watch("start_date"), "YYYY-MM-DD").$d}
                          format="YYYY-MM-DD"
                          editable={true}
                          onChange={(date) => {
                            field.onChange(date?.format?.("YYYY-MM-DD"));
                            // endDateChange(date?.format?.("YYYY-MM-DD"));
                          }}
                          disabled={isLoading}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-sm-6">
                  <div className="">
                    <input
                      type="text"
                      placeholder="Search Application"
                      className="form-control"
                      disabled={isLoading}
                      {...register("search", {
                        onChange: (e) => searchInput(e),
                      })}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="">
                    <button
                      className="btn btn-primary px-4"
                      type="submit"
                      disabled={watch("fields")?.length > 10}
                    >
                      Show
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Collapse>
        <div>
          {isApplicationLoaded ? (
            <Table columns={columns} data={filtered} />
          ) : (
            <Loader />
          )}
        </div>
      </div>

      <Modal
        show={otpModalBox}
        onHide={otpModalClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-dark">Verify OTP</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="p-3">
            <form onSubmit={handleSubmit(verifyOTP)}>
              <div className="row g-3 justify-content-center">
                <div className="col-12">
                  <div className="text-center text-dark">
                    <h6 className="m-0">Please enter OTP </h6>
                  </div>
                </div>
                <div className="col-12">
                  <div>
                    <div className="otpbox">
                      <Controller
                        control={control}
                        name="otp"
                        rules={{ required: true }}
                        render={({ field }) => (
                          <OTPInput
                            value={field.value}
                            onChange={(e) => field.onChange(e)}
                            autoFocus={true}
                            secure={false}
                            OTPLength={4}
                            otpType="number"
                            isDisabled={true}
                          />
                        )}
                      />
                    </div>
                    {error.otp && (
                      <div className="text-center mt-2 text-danger small fw-bold">
                        OTP is required
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12">
                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={isLoading}
                    >
                      Verify OTP
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Applications;
