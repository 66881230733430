import React, { useEffect, useState } from "react";
import Api from "../../Config/Api";
import { useLocation } from "react-router-dom";
import GifImg from "../../assets/images/loader.gif";
import { toast } from "react-toastify";

const ApplicationActivities = () => {
  const location = useLocation();
  const {
    id,
    application_id,
    applicant_name,
    applicant_default_img,
    mobile,
  } = location.state;
// console.log(location.state);
  const [activities, setActivities] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const fetchActivities = async () => {
    try {
      const res = await Api(`application-activities/${id}`);
      // console.log(res);
      if (res.data.status === "success") {
        setActivities(res.data.data);
        setIsDataLoaded(true);
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsDataLoaded(true);
      }
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  useEffect(() => {
    fetchActivities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   console.log(activities);
  // }, [activities]);

  return (
    <>

      <div className="bg-white p-3 rounded p-3 shadow">
        <div className="d-flex justify-content-between mb-3">
          <div className="flex-grow-1">
            <h5 className="text-dark">Application Activities</h5>
          </div>
        </div>
        <div>
          <div className="mb-3">
            <div className="row g-3">
              <div className="col-lg">
                <div className="bg-light p-3 rounded-4 text-center">
                  <img
                    style={{ height: "50px" }}
                    src={applicant_default_img}
                    alt="applicant_name"
                  />
                </div>
              </div>
              <div className="col-lg">
                <div className="bg-light p-3 rounded-4">
                  <label className="form-label">Application ID</label>
                  <p className="text-dark fw-bold">{application_id}</p>
                </div>
              </div>
              {/* <div className="col-lg">
                <div className="bg-light p-3 rounded-4">
                  <label className="form-label">Aadhar Number</label>
                  <p className="text-dark fw-bold">{aadhar_no}</p>
                </div>
              </div> */}
              <div className="col-lg">
                <div className="bg-light p-3 rounded-4">
                  <label className="form-label">Applicant Name</label>
                  <p className="text-dark fw-bold">{applicant_name}</p>
                </div>
              </div>
              <div className="col-lg">
                <div className="bg-light p-3 rounded-4">
                  <label className="form-label">Mobile Number</label>
                  <p className="text-dark fw-bold">{mobile}</p>
                </div>
              </div>
            </div>
          </div>
          {isDataLoaded ? (
            activities.length >= 1 ? (
              <div className="table-responsive">
                <table className="table table-bordered m-0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Title</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {activities.map((data, i) => {
                      return (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{data.title}</td>
                          <td>{data.created_at}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="alert alert-danger m-0">
                <p>No Activities</p>
              </div>
            )
          ) : (
            <div className="loaderBox">
              <img src={GifImg} alt="Loader" />
              <p>Loading...</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ApplicationActivities;
