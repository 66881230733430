import React, { useEffect } from "react";
import logo from "../../../assets/images/logo.png";
import { NavLink, useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import SidebarMenu from "./Components/SidebarMenu";
import { BiDesktop, BiFile, BiHistory } from "react-icons/bi";
import useWindowDimensions from "../../../Hooks/useWindowDimensions";

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: <BiDesktop />,
  },
  {
    path: "/applications",
    name: "Applications",
    icon: <BiFile />,
  },
  {
    path: "/payment-history",
    name: "Payment History",
    icon: <BiHistory />,
  },
];

const Sidebar = ({ isBarOpen, setIsBarOpen, isBarHover, setIsBarHover }) => {
  const location = useLocation();
  const { width } = useWindowDimensions();
  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.5,
      },
    },
  };

  let transition = {};

  useEffect(() => {
    if (width < 767) {
      setIsBarHover(true);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (width < 767) {
      setIsBarOpen(false);
    } else {
      transition.transition = {
        duration: 0.5,
        type: "spring",
        damping: 10,
      };
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  return (
    <>
      <div
        // animate={{
        //   width: isBarOpen ? "250px" : "80px",
        //   transition: {
        //     duration: 0.5,
        //     type: "spring",
        //     damping: 10,
        //   },
        // }}
        // className={`sidebarbox ${isBarOpen ? "active" : ""}`}
        style={{
          width: isBarOpen ? "250px" : isBarHover ? "250px" : "80px",
        }}
        className={`sidebarbox ${isBarOpen ? "active" : ""} ${
          isBarHover ? "hover" : ""
        }`}
        onMouseEnter={() => setIsBarHover(true)}
        onMouseLeave={() => setIsBarHover(false)}
      >
        <div className="sidebarheader">
          <AnimatePresence>
            <motion.div
              variants={showAnimation}
              initial="show"
              animate="show"
              exit="hidden"
              className=""
            >
              <img src={logo} style={{ height: "50px" }} alt="logo" />
            </motion.div>
          </AnimatePresence>
        </div>
        <div className="sidebarmenu">
          <nav>
            <ul>
              {routes.map((route, index) => {
                if (route.subRoutes) {
                  return (
                    <SidebarMenu
                      setIsBarOpen={setIsBarOpen}
                      route={route}
                      showAnimation={showAnimation}
                      isBarOpen={isBarOpen}
                      key={index}
                    />
                  );
                }

                return (
                  <li key={index}>
                    <NavLink to={route.path}>
                      <div className="menuicon">{route.icon}</div>
                      <AnimatePresence>
                        {(isBarOpen || isBarHover) && (
                          <motion.div
                            variants={showAnimation}
                            initial="hidden"
                            animate="show"
                            exit="hidden"
                            className="menutext"
                          >
                            {route.name}
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </nav>
        </div>
      </div>
      <div
        onClick={() => setIsBarOpen(false)}
        className={`sidebar_overlay ${isBarOpen ? "show" : ""}`}
      ></div>
    </>
  );
};

export default Sidebar;
