import React, { useEffect } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { FiCheckCircle } from "react-icons/fi";
const ApplicationThankyou = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { data } = location.state || {};

  useEffect(() => {
    // console.log(data);
    if (!data) {
      navigate("/applications");
      // toast.error("Something went wrong!", {
      //   position: toast.POSITION.BOTTOM_RIGHT,
      // });
    }
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="bg-white p-3 rounded p-3 shadow">
        <div>
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="thankyou_box">
                <FiCheckCircle className="fs-1 text-success mb-3" />
                <h5>The amount has been transferred successfully!</h5>
                <h6>
                  Application Id : <span>{data?.data?.application_id}</span>
                </h6>
                <h6>
                  Applicant Name : <span>{data?.data?.applicant_name}</span>
                </h6>
                {/* <h6>
                  Payment Method : <span>Cash</span>
                </h6> */}
                <h6>
                  Amount : <b>₹ {data?.amount?.final_amount}</b>
                </h6>

                <Link to="/applications" className="btn btn-success px-4 mt-3">
                  Go to Application List
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplicationThankyou;
