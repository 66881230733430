import React from "react";
import DataTable from "react-data-table-component";
import { BsFillCaretDownFill } from "react-icons/bs";

const Table = ({ columns, data }) => {
  const caseInsensitiveSort = (rowA, rowB) => {
    const a = rowA.status.toLowerCase();
    const b = rowB.status.toLowerCase();
    if (a > b) {
      return 1;
    }
    if (b > a) {
      return -1;
    }
    return 0;
  };

  let newobj = columns.find((a) => a.name.toLowerCase() === "status");
  // console.log(newobj);
  if (!!newobj) {
    newobj.sortFunction = caseInsensitiveSort;
  }

  //  Internally, customStyles will deep merges your customStyles with the default styling.
  const customStyles = {
    table: {
      style: {
        border: "1px solid rgba(0,0,0,.12)",
      },
    },
    headCells: {
      style: {
        padding: "5px",
        "&:not(:last-child)": {
          borderRight: "1px solid rgba(0,0,0,.12)",
        },
      },
    },
    cells: {
      style: {
        padding: "5px",
        wordBreak: "break-word",
        lineHeight: "1.4",
        "&:not(:last-child)": {
          borderRight: "1px solid rgba(0,0,0,.12)",
        },
      },
    },
    rows: {
      style: {
        borderColor: "#000",
        "&:hover": {
          outline: "0 !important",
          borderColor: "rgba(0,0,0,.12) !important",
        },
      },
    },
  };

  return (
    <DataTable
      columns={columns}
      data={data}
      sortIcon={<BsFillCaretDownFill />}
      customStyles={customStyles}
      defaultSortFieldId={1}
      striped
      highlightOnHover
      pagination
      dense
    />
  );
};

export default Table;
